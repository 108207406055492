/* Public tracking events */
window.analytics = {
  /* Finmo Choose Account events */
  finmoChooseAccount: {
    /** @param {String} provider */
    continueWithButtonClick: (provider) => trackFinmoChooseAccount('continue_with_button_click', { provider }),
  },

  /* Landing page events */
  landing: {
    existingUserButtonClick: () => trackLanding('existing_user_button_click'),

    spotlightWebsiteLinkClick: () => trackLanding('spotlight_website_link_click'),

    newUserButtonClick: () => trackLanding('new_user_button_click'),
  },

  /* Login page events */
  login: {
    /** @param {String} provider */
    continueWithButtonClick: (provider) => trackLogin('continue_with_button_click', { provider }),

    getFinmoProButtonClick: () => trackLogin('get_finmo_pro_button_click'),

    learnMoreButtonClick: () => trackLogin('learn_more_button_click'),

    signUpLinkClick: () => trackLogin('sign_up_link_click'),
  },

  /* Signup page events */
  signup: {
    /** @param {String} provider */
    getFinmoProButtonClick: () => trackSignup('get_finmo_pro_button_click'),

    learnMoreButtonClick: () => trackSignup('learn_more_button_click'),

    loginLinkClick: () => trackSignup('login_link_click'),

    signUpWithButtonClick: (provider) => trackSignup('sign_up_with_button_click', { provider }),
  },
};

/* Helper functions */

/**
 * Helper function for emitting analytic events.
 * @param {String} area
 * @return {(action: String, attributes: Object) => void}
 */
const track =
  (area) =>
  (action, attributes = {}) => {
    gtag('event', `${area}_${action}`, {
      category: area,
      ...attributes,
    });
  };

/* Category helpers */
const trackLanding = track('landing');
const trackLogin = track('login');
const trackSignup = track('signup');
const trackFinmoChooseAccount = track('finmo_choose_account');
